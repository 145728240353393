<template>
  <footer class="footer-container">
    <div class="footer-columns">
      <div class="footer-column">
        <a class="footer-link" href="mailto:post@renergico.com">
          post@renergico.com
        </a>
        <a class="footer-link" href="tel:0034685264009">
          +34 685 26 40 09
        </a>
      </div>
      <div class="LogoWrapper">
        <div class="footer-logo">
          <img src="ebra.jpg" alt="Ebra Logo">
        </div>
        <div class="footer-logo">
          <img src="logo.jpg" alt="Renergico Logo">
        </div>
        <div class="footer-logo">
          <img src="logobatteryplat.png" alt="Batterplat Logo">
        </div>
      </div>
      <div class="footer-column">
        <!-- Add any additional content here if needed -->
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-text">© 2024 Renergico. All Rights Reserved.</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footerComponent'
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.footer-container {
  background-color: #333; /* Darker gray for better contrast with gray logo */
  color: white;
  padding: 20px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.footer-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  text-align: center;
}

.footer-link {
  color: #8DC63F; 
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-family: sans-serif;
}

.footer-link:hover {
  text-decoration: underline;
}

.LogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  margin: 0 10px; /* Space between logos */
  
}

.footer-logo img {
  max-width: 300px; /* Adjust size as needed */
  max-height: 100px;
  display: block;
  margin: 0 auto;
  border-radius: 5px; /* Optional: rounded corners for a modern touch */
  background-color: #fff; /* Optional: white background to make the logo stand out */
  padding: 5px; /* Optional: some padding around the logo */
}

.footer-bottom {
  border-top: 1px solid #8DC63F; 
  padding-top: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-bottom .footer-text {
  margin: 5px;
  font-size: 1em;
}
</style>
